<template>
  <div>
    <h1 class="text-34 font-black tracking-tight mb-2">
      {{ businessName }}
    </h1>
    <p class="text-12 font-normal mb-4 text-marketplace-blue text-opacity-75">
      Select a {{ businessName }} outlet.
    </p>
    <div class="mb-5">
      <div class="relative">
        <input
          type="search"
          name="search"
          class="bg-gray-200 rounded placeholder-gray-500 focus:outline-none pl-10 pr-2 py-3 text-sm w-full"
          placeholder="Search for outlets or location"
          v-model="searchQuery"
          @keyup.prevent="vendorBranch(searchQuery)"
        />
        <ion-icon
          name="search-outline"
          class="text-gray-500 absolute z-10 text-lg ml-3 left-0"
          style="top: 50%; transform: translateY(-50%);"
        ></ion-icon>
      </div>
    </div>
    <div class="overflow-y-scroll min-h-550px">
      <sm-loader class="flex justify-center" v-if="branch.loading" />
      <div class="grid grid-cols-1 gap-4" v-else>
        <branch-list
          v-for="(branch, i) in branch.data"
          :key="i"
          :branch="branch"
          v-on="$listeners"
          v-bind="$attrs"
        />
      </div>
      <no-result v-if="noBranchFound" class="flex justify-center mt-4">
        No Branch Found
      </no-result>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    vendor: {
      type: Object,
      required: true
    }
  },
  inheritAttrs: false,
  components: {
    BranchList: () => import("./BranchList"),
    NoResult: () => import("../NoResult")
  },
  data() {
    return {
      searchQuery: "",
      branch: this.$options.resource([])
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        selectBranch: branch => this.$emit("selectBranch", branch)
      };
    },
    noBranchFound() {
      return this.branch.data.length === 0;
    },
    businessName() {
      return this.vendor?.business_name;
    }
  },
  beforeMount() {
    this.vendorBranch();
  },
  methods: {
    async vendorBranch(search = "") {
      this.branch.loading = true;
      await this.sendRequest(
        "personal.marketplace.branch",
        typeof this.vendor === "object" ? this.vendor?.id : this.vendor,
        search,
        {
          success: response =>
            (this.branch.data = response.data.datatable.data),
          error: error => console.log(error)
        }
      );
      this.branch.loading = false;
    }
  }
};
</script>
